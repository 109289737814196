import Vue from "vue";
import VueRouter from "vue-router";
import { available, getGameLang, getUserLang, i18n } from "@/lang";
import store from "@/store";
const LoginPage = () => import("@/page/login-page");
const RegisterPage = () => import("@/page/register-page");
const RecoveryPage = () => import("@/page/recovery-page");
// const NewsPage = () => import("@/page/news-page");
import CalculatorPage from "@/page/calculator-page";
const SkillPage = () => import("@/page/skill-page");
const SkillsPage = () => import("@/page/skills-page");
const ProfilePage = () => import("@/page/profile-page");
const SettingsPage = () => import("@/page/settings-page");
const ItemCatalogPage = () => import("@/page/item-catalog-page");
const ItemPage = () => import("@/page/item-page");
const DropPage = () => import("@/page/drop-page");
const DashboardPage = () => import("@/page/dashboard-page");
const DashboardMetricPage = () => import("@/page/dashboard/dash-metric");
const DashboardUploadPage = () => import("@/page/dashboard/dash-upload");
const DashboardManageItemsPage = () => import("@/page/dashboard/manage-items");
const DashboardManageExpModifiers = () =>
  import("@/page/dashboard/manage-exp-modifiers");
const DashboardManageFixModifiers = () =>
  import("@/page/dashboard/manage-fix-modifiers");
const DashboardManageGuildBonuses = () =>
  import("@/page/dashboard/manage-guild-bonuses");
const DashboardManageKnowledge = () =>
  import("@/page/dashboard/manage-knowledge");
const DashboardManageSkill = () => import("@/page/dashboard/manage-skill");
import { RIGHT } from "@/api";

Vue.use(VueRouter);

export const ROUTES = {
  // NEWS: "NewsPage",
  LOGIN: "LoginPage",
  REGISTER: "RegisterPage",
  RECOVERY: "RecoveryPage",
  CALCULATOR: "CalculatorPage",
  PROFILE: "ProfilePage",
  SETTINGS: "SettingsPage",
  ITEMS_CATALOG: "ItemsPage",
  ITEM: "ItemPage",
  SKILLS: "SkillsPage",
  SKILL: "SkillPage",
  DROP: "DropPage",
  DASHBOARD: "DashboardPage",
  DASHBOARD_METRIC: "DashboardMetric",
  DASHBOARD_UPLOAD: "DashboardUpload",
  DASHBOARD_MANAGE_ITEMS: "DashboardManageItems",
  DASHBOARD_EXP_MODIFIERS: "DashboardExpModifiers",
  DASHBOARD_FIX_MODIFIERS: "DashboardFixModifiers",
  DASHBOARD_GUILD_BONUSES: "DashboardGuildBonuses",
  DASHBOARD_KNOWLEDGE: "DashboardKnowledge",
  DASHBOARD_SKILL: "DashboardSkill",
};

export const DASH_ROUTES = [
  {
    route: ROUTES.DASHBOARD_METRIC,
    right: 0,
  },
  {
    route: ROUTES.DASHBOARD_UPLOAD,
    right: RIGHT.UPLOAD_PKG,
  },
  {
    route: ROUTES.DASHBOARD_MANAGE_ITEMS,
    right: RIGHT.MANAGE_ITEMS,
  },
  {
    route: ROUTES.DASHBOARD_EXP_MODIFIERS,
    right: RIGHT.MANAGE_MODIFIERS,
  },
  {
    route: ROUTES.DASHBOARD_FIX_MODIFIERS,
    right: RIGHT.MANAGE_MODIFIERS,
  },
  {
    route: ROUTES.DASHBOARD_GUILD_BONUSES,
    right: RIGHT.MANAGE_GUILD_BONUSES,
  },
  {
    route: ROUTES.DASHBOARD_KNOWLEDGE,
    right: RIGHT.MANAGE_KNOWLEDGE,
  },
  {
    route: ROUTES.DASHBOARD_SKILL,
    right: RIGHT.MANAGE_SKILL,
  },
];

export function getUser() {
  if (store.state.token.length > 0 && store.state.username.length < 1) {
    return store.dispatch("checkSession").then((e) => {
      return {
        auth: e.token.length > 0,
        rights: e.rights,
      };
    });
  }
  return Promise.resolve({
    auth: store.state.token.length > 0,
    rights: store.state.rights,
  });
}

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "",
      exact: true,
      redirect: () => {
        return getUserLang() + "/calculator";
      },
    },
    {
      // name: ROUTES.NEWS,
      path: "/:lang",
      // component: NewsPage,
      redirect: () => {
        return getUserLang() + "/calculator";
      },
    },
    {
      name: ROUTES.LOGIN,
      path: "/:lang/user/login",
      component: LoginPage,
      beforeEnter(to, from, next) {
        if (store.state.token.length > 0) {
          next({
            name: ROUTES.PROFILE,
            params: to.params,
          });
        } else {
          next();
        }
      },
    },
    {
      name: ROUTES.REGISTER,
      path: "/:lang/user/register",
      component: RegisterPage,
      beforeEnter(to, from, next) {
        if (store.state.token.length > 0) {
          next({
            name: ROUTES.PROFILE,
            params: to.params,
          });
        } else {
          next();
        }
      },
    },
    {
      name: ROUTES.RECOVERY,
      path: "/:lang/user/recovery",
      component: RecoveryPage,
      beforeEnter(to, from, next) {
        if (store.state.token.length > 0) {
          next({
            name: ROUTES.PROFILE,
            params: to.params,
          });
        } else {
          next();
        }
      },
    },
    {
      name: ROUTES.CALCULATOR,
      path: "/:lang/calculator",
      component: CalculatorPage,
    },
    {
      name: ROUTES.PROFILE,
      path: "/:lang/profile",
      component: ProfilePage,
      meta: {
        auth: true,
        right: 0,
      },
    },
    {
      name: ROUTES.SETTINGS,
      path: "/:lang/settings",
      component: SettingsPage,
      meta: {
        auth: false,
        right: 0,
      },
    },
    {
      name: ROUTES.ITEMS_CATALOG,
      path: "/:lang/items/:category",
      component: ItemCatalogPage,
      meta: {
        auth: false,
        right: 0,
      },
    },
    {
      name: ROUTES.ITEM,
      path: "/:lang/item/:id",
      component: ItemPage,
      meta: {
        auth: false,
        right: 0,
      },
    },
    {
      name: ROUTES.SKILLS,
      path: "/:lang/skills",
      component: SkillsPage,
      meta: {
        auth: false,
        right: 0,
      },
    },
    {
      name: ROUTES.SKILL,
      path: "/:lang/skill/:id",
      component: SkillPage,
      meta: {
        auth: false,
        right: 0,
      },
    },
    {
      name: ROUTES.DROP,
      path: "/:lang/drop",
      component: DropPage,
      meta: {
        auth: false,
        right: 0,
      },
    },
    {
      name: ROUTES.DASHBOARD,
      path: "/:lang/dashboard",
      component: DashboardPage,
      meta: {
        auth: true,
        right: RIGHT.UPLOAD_PKG,
      },
      children: [
        {
          name: ROUTES.DASHBOARD_METRIC,
          path: "",
          component: DashboardMetricPage,
          meta: {
            auth: true,
            right: RIGHT.UPLOAD_PKG,
          },
        },
        {
          name: ROUTES.DASHBOARD_UPLOAD,
          path: "upload",
          component: DashboardUploadPage,
          meta: {
            auth: true,
            right: RIGHT.UPLOAD_PKG,
          },
        },
        {
          name: ROUTES.DASHBOARD_MANAGE_ITEMS,
          path: "manage-items",
          component: DashboardManageItemsPage,
          meta: {
            auth: true,
            right: RIGHT.MANAGE_ITEMS,
          },
        },
        {
          name: ROUTES.DASHBOARD_EXP_MODIFIERS,
          path: "manage-exp-modifiers",
          component: DashboardManageExpModifiers,
          meta: {
            auth: true,
            right: RIGHT.MANAGE_MODIFIERS,
          },
        },
        {
          name: ROUTES.DASHBOARD_FIX_MODIFIERS,
          path: "manage-fix-modifiers",
          component: DashboardManageFixModifiers,
          meta: {
            auth: true,
            right: RIGHT.MANAGE_MODIFIERS,
          },
        },
        {
          name: ROUTES.DASHBOARD_GUILD_BONUSES,
          path: "manage-guild-bonuses",
          component: DashboardManageGuildBonuses,
          meta: {
            auth: true,
            right: RIGHT.MANAGE_GUILD_BONUSES,
          },
        },
        {
          name: ROUTES.DASHBOARD_KNOWLEDGE,
          path: "manage-knowledge",
          component: DashboardManageKnowledge,
          meta: {
            auth: true,
            right: RIGHT.MANAGE_KNOWLEDGE,
          },
        },
        {
          name: ROUTES.DASHBOARD_SKILL,
          path: "manage-skill",
          component: DashboardManageSkill,
          meta: {
            auth: true,
            right: RIGHT.MANAGE_SKILL,
          },
        },
      ],
    },
    {
      name: "build_loader",
      path: "/build/:id",
      redirect: (route) => {
        if (route) {
          const id = parseInt(route.params.id);
          const locale = getGameLang(getUserLang());
          if (!isNaN(id) && id > 0) {
            store.dispatch("calculator/initialize", locale).then(() => {
              return store.dispatch("calculator/loadServer", {
                id,
                locale,
              });
            });
          }
        }
        return {
          name: ROUTES.CALCULATOR,
          params: {
            lang: getUserLang(),
          },
        };
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const code = to.params.lang || getUserLang();
  if (!available.find((l) => l.code === code)) {
    next("/" + getUserLang());
    return;
  } else if (i18n.locale !== code) {
    i18n.locale = code;
  }
  if (to.meta && to.meta.auth) {
    getUser().then((e) => {
      if (!e.auth) {
        console.warn("[Router] Not authorized");
        next({
          name: ROUTES.LOGIN,
          params: to.params,
        });
      } else if (to.meta.right && !e.rights.includes(to.meta.right)) {
        console.warn("[Router] No permissions");
        next({
          name: ROUTES.NEWS,
          params: to.params,
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
